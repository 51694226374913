<template>
  <div class="h-full">
    <div class="flex flex-col h-full">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
            <vs-select class="vs-select-small" :value="null" v-model="filter.id_proyek">
              <vs-select-item class="vs-select-small" text="Semua"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
            <vx-input-group class="w-52">
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
          </div>
          <div class="flex items-center">
            <vs-button class="px-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="getData">
              {{ loading.getData ? 'Loading...' : 'Filter' }}
            </vs-button>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p class="text-xs font-medium text-dark text-bold">{{ this.data.items.length }} Result</p>
            <QueryEditor v-if="$can('view_query')" code="AP_PAYMENT_REQUEST"/>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <table class="table-fixed table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-10">#</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-16">Action</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-14">
                  <vs-checkbox size="small" class="text-xs" v-model="data.checkAll" @change="toggleCheckAll($event.target.checked)"></vs-checkbox>
                </th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">No. PR</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-20">Proyek</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">PR Date</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">PR Due</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Status Approval</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">Approval Progress</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Status</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">Total</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">Total Paid</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">Total Balance</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Created By</th>
              </tr>
            </thead>

            <!-----------TABLE BODY------------>
            <tbody>
              <tr v-for="(item, index) in data.items" :key="index" @dblclick="showModalView(item)">
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ index + 1 }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">
                  <vs-button class="py-2 px-3" size="small" type="border" icon-pack="feather" icon="icon-eye" @click="showModalView(item)"/>
                </td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-center" @click.stop>
                  <vs-checkbox size="small" v-model="item.checked"/>
                </td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ item.no_payment_request }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ item.kode_proyek }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ item.tgl_payment_request }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ item.tgl_due }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ item.status_approval }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs cursor-pointer underline" @click="showModalApproval(item)">Lihat Progress</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ item.status }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total | idr }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ item.total_paid | idr }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs text-right">{{ (item.total_balance) | idr }}</td>
                <td class="border border-solid d-theme-border-grey-light px-2 py-1 text-xs">{{ item.created_by }}</td>
              </tr>
            </tbody>

            <!-----------TABLE FOOT------------>
            <tfoot v-if="data.items.length > 0">
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ summary.grandTotal | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ summary.grandTotalPaid | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right">{{ summary.grandTotalBalance | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 text-xs text-right"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light">
        <div class="flex flex-wrap items-center p-2 justify-end">
          <div class="flex">
            <div class="h-8">
              <vs-button :disabled="checkedItems.length < 1" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-printer" @click="print">Print</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
    <ViewPaymentRequest :isActive.sync="modalView.active" @success="refresh" :idPaymentRequest="modalView.item.id"/>

  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ApRepository from '@/repositories/accounting/ap-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'
import ModalApprovalProgress from '@/views/components/approval-progress/ModalApprovalProgress'

export default {
  name: 'TabPaymentRequest',
  props: ['isActive'],
  components: {
    ModalApprovalProgress,
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    ViewPaymentRequest: () => import('@/views/pages/accounting/account-payable/ap/part/ViewPaymentRequest'),
    flatPickr
  },
  mixins: [printoutMixin],
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    checkedItems () {
      return _.filter(this.data.items, item => item.checked)
    },
    summary () {
      return {
        grandTotal: _.sumBy(this.data.items, item => parseFloat(item.total)),
        grandTotalPaid: _.sumBy(this.data.items, item => parseFloat(item.total_paid)),
        grandTotalBalance: _.sumBy(this.data.items, item => parseFloat(item.total_balance))
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      errors: null,
      proyeks: [],
      loading: {
        getData: false
      },
      modalView: {
        active: false,
        item: {}
      },
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD'),
        search: null
      },
      data: {
        checkAll: false,
        items: []
      }
    }
  },
  methods: {
    initData () {
      this.getProyek()
      this.isDataInited = true
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.getData = true

      ApRepository.getPaymentRequest(this.filter)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            return _.extend({}, item, { checked: false })
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    showModalView (item) {
      this.modalView.item = item
      this.modalView.active = true
    },

    showModalApproval (item) {
      this.modalApproval.type = 'PAYMENT-REQUEST'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    refresh () {
      this.data.items = []
      this.getData()
    },

    print () {
      this.showPrintOutFile('PAYMENT_REQUEST', { ids: _.map(this.checkedItems, item => item.id) })
    },

    toggleCheckAll (isChecked) {
      this.data.items = _.map(this.data.items, item => {
        item.checked = isChecked
        return item
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
