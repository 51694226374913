<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <!------------ box header ------------->
      <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="flex flex-wrap space-x-3 px-2 py-1 vx-card shadow-none">
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
            <vs-select class="vs-select-small" :value="null" v-model="filter.id_proyek">
              <vs-select-item class="vs-select-small" text="Semua"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
            <vx-input-group class="w-52">
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full" type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
          </div>
          <div class="flex items-center">
            <vs-button class="px-3 text-xs text-center" color="primary" :disabled="loading.getData" @click="getData">
              {{ loading.getData ? 'Loading...' : 'Filter' }}
            </vs-button>
          </div>
        </div>
        <div class="flex justify-between border-t border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1);">
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <p class="text-xs font-medium text-dark text-bold">{{ this.totalTransactions }} Result</p>
            <QueryEditor v-if="$can('view_query')" code="AP_PAID"/>
          </div>
          <div class="flex items-center justify-start space-x-3 px-2 h-8">
            <div class="flex items-center cursor-pointer space-x-1" @click="toggleExpanded">
              <feather-icon :icon="isAllExpanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svgClasses="h-3 w-3"/>
              <span class="text-xs">{{ isAllExpanded ? 'Collapse All' : 'Expand All' }}</span>
            </div>
          </div>
        </div>
      </div>

      <!----------- box content ------------->
      <div class="flex-1 border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
        <div class="box-content-height overflow-auto">
          <table class="table-fixed table-sticky border-collapse w-full">
            <!-----------TABLE HEAD------------>
            <thead>
              <tr class="d-theme-dark-bg">
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">#</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-40">No. Trx</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Date Trx</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-24">Status</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-16">Proyek</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-26">Date Paid</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-48">Rekanan</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-84">Remark</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-40">Total</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-40">Total Paid</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Bukti Bayar</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">No. PV</th>
                <th class="border border-t-0 border-solid d-theme-border-grey-light p-2 text-center text-xs w-32">No. Jurnal</th>
              </tr>
            </thead>

            <!-----------TABLE BODY------------>
            <tbody v-for="group in data.groups" :key="group.uuid">
              <!--group-->
              <tr class="cursor-pointer hover-bg-primary-transparent-25" @click="group.expanded = !group.expanded">
                <td class="border border-solid d-theme-border-grey-light p-2 font-medium" colspan="13">
                  <div class="flex items-center">
                    <feather-icon :icon="group.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                    <span class="font-bold text-sm">Group: {{ group.name }}</span>
                  </div>
                </td>
              </tr>
              <!--header-->
              <template v-if="group.expanded">
                <template v-for="header in group.headers">
                  <tr :key="header.uuid" class="cursor-pointer hover-bg-primary-transparent-25" @click="header.expanded = !header.expanded">
                    <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap" colspan="13">
                      <div class="flex items-center ml-5">
                        <feather-icon :icon="header.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" svg-classes="h-5 w-5" class="mr-2"/>
                        <span class="font-bold">{{ header.name }}</span>
                      </div>
                    </td>
                  </tr>
                  <!--items-->
                  <template v-if="header.expanded">
                    <tr v-for="(item, index) in header.items" :key="item.uuid">
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left"><span class="ml-12">{{ index + 1 }}</span></td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_trx }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.date_trx }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.status }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode_proyek }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.date }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama_rekanan }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.remark }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_paid | idr }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">
                        <span v-if="item.bukti_transfer_url_list.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item)">Lihat Files</span>
                        <span v-else class="text-sm">-</span>
                      </td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_pv || '-' }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_jurnal }}</td>
                    </tr>
                  </template>
                </template>
                <!--group summary-->
                <tr class="d-theme-dark-bg">
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-center uppercase" colspan="9">Total Group {{ group.name }}</td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right">{{ group.totalPaid | idr }}</td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right"></td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right"></td>
                  <td class="p-2 border border-solid d-theme-border-grey-light text-left text-xs whitespace-no-wrap font-bold text-right"></td>
                </tr>
              </template>
            </tbody>
            <!-----------TABLE FOOT------------>
            <tfoot v-if="totalTransactions > 0">
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right">{{ summary.grandTotalPaid | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 font-bold text-primary text-sm text-right"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!------------ box footer ------------->
      <div class="flex-none">
        <div class="flex flex-wrap items-center p-2 h-12 border border-solid border-t-0 border-l-0 border-r-0 d-theme-border-grey-light"></div>
      </div>
    </div>

    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import ApRepository from '@/repositories/accounting/ap-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'TabApPaid',
  props: ['isActive'],
  components: {
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    FilesViewer: () => import('@/views/components/files-viewer/FilesViewer'),
    flatPickr
  },
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        if (val && !this.isDataInited) this.initData()
      }
    }
  },
  computed: {
    totalTransactions () {
      return _.sumBy(this.data.groups, group => _.sumBy(group.headers, header => header.items.length))
    },
    summary () {
      return {
        grandTotalPaid: _.sumBy(this.data.groups, item => parseFloat(item.totalPaid))
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isAllExpanded: true,
      proyeks: [],
      modalFiles: {
        filesUrl: [],
        active: false
      },
      loading: {
        getData: false
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD'),
        search: null
      },
      data: {
        groups: []
      }
    }
  },
  methods: {
    initData () {
      this.getProyek()
      this.isDataInited = true
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading.getData = true
      ApRepository.getAccountPayablePaid(this.filter)
        .then(response => {
          this.data.groups = this.buildGroups(_.cloneDeep(response.data.data))
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.getData = false
        })
    },

    buildGroups (data) {
      const generateHeaderItems = (group, idTrx) => {
        return _.map(_.filter(data, item => (item.group === group && item.id_trx_h === idTrx)), item => {
          item.uuid = uuid()
          item.id_trx = item.is_group_by_trx_header ? item.id_trx_h : item.id_trx_d
          item.no_trx = item.is_group_by_trx_header ? item.no_trx_h : item.no_trx_d
          item.checked = false
          return item
        })
      }

      const generateHeaders = (group) => {
        const groupTrxItems = _.filter(data, it => it.group === group)
        return _.map(_.uniqBy(groupTrxItems, it => it.id_trx_h), it => {
          const items = generateHeaderItems(it.group, it.id_trx_h)
          return {
            uuid: uuid(),
            name: it.no_trx_h,
            items: items,
            totalPaid: _.sumBy(items, item => item.total_paid),
            expanded: true
          }
        })
      }

      const generateGroups = () => {
        return _.map(_.uniqBy(data, item => item.group), item => {
          const headers = generateHeaders(item.group)
          return {
            uuid: uuid(),
            name: item.group,
            headers: headers,
            total: _.sumBy(headers, item => item.total),
            totalPaid: _.sumBy(headers, item => item.totalPaid),
            expanded: true
          }
        })
      }

      return generateGroups()
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.bukti_transfer_url_list
      this.modalFiles.active = true
    },

    toggleExpanded () {
      this.isAllExpanded = !this.isAllExpanded
      this.data.groups = _.map(this.data.groups, group => {
        group.expanded = this.isAllExpanded
        group.headers = _.map(group.headers, header => {
          header.expanded = this.isAllExpanded
          return header
        })
        return group
      })
    }
  }
}
</script>

<style scoped>
/*navbar floating*/
.navbar-floating .box-content-height {
  height: calc(100vh - 314px);
}
.navbar-floating.footer-sticky .box-content-height {
  height: calc(100vh - 337px);
}
.navbar-floating.footer-hidden .box-content-height {
  height: calc(100vh - 283px);
}

/*navbar sticky*/
.navbar-sticky .box-content-height {
  height: calc(100vh - 300px);
}
.navbar-sticky.footer-sticky .box-content-height {
  height: calc(100vh - 323px);
}
.navbar-sticky.footer-hidden .box-content-height {
  height: calc(100vh - 269px);
}

/*navbar static*/
.navbar-static .box-content-height {
  height: calc(100vh - 282px);
}
.navbar-static.footer-sticky .box-content-height {
  height: calc(100vh - 305px);
}
.navbar-static.footer-hidden .box-content-height {
  height: calc(100vh - 251px);
}

/*navbar hidden*/
.navbar-hidden .box-content-height {
  height: calc(100vh - 237px);
}
.navbar-hidden.footer-sticky .box-content-height {
  height: calc(100vh - 260px);
}
.navbar-hidden.footer-hidden .box-content-height {
  height: calc(100vh - 206px);
}

/*table sticky*/
.table-sticky thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.table-sticky tfoot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
