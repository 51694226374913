const getDefaultState = () => {
  return {
    // tab ap list
    tabApList: {},

    // tab payment voucher
    tabPv: {},

    // tab ap paid
    tabApPaid: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_TAB_AP_LIST (state, payload) {
      state.tabApList = payload
    },
    SET_TAB_PV (state, payload) {
      state.tabPv = payload
    },
    SET_TAB_AP_PAID (state, payload) {
      state.tabApPaid = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
