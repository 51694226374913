import { render, staticRenderFns } from "./TabApPaid.vue?vue&type=template&id=6ffbce8a&scoped=true&"
import script from "./TabApPaid.vue?vue&type=script&lang=js&"
export * from "./TabApPaid.vue?vue&type=script&lang=js&"
import style0 from "./TabApPaid.vue?vue&type=style&index=0&id=6ffbce8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ffbce8a",
  null
  
)

export default component.exports