<template>
  <div class="no-scroll-content flex flex-col border border-solid d-theme-border-grey-light">
    <!--tabs-->
    <div class="flex-none border-t-0 border-b border-l-0 border-r-0 border-solid d-theme-border-grey-light">
      <div class="p-2 vx-card shadow-none rounded-none">
        <div class="flex h-auto">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tab, index) in tabs.items"
                :key="index"
                :class="{'text-primary font-bold bg-primary-transparent-25': tabs.active === tab.code, 'hover-bg-primary-transparent-25': tabs.active !== tab.code}"
                @click="tabs.active = tab.code"
                class="cursor-pointer px-2 py-1 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-t-0 border-b-0 border-l-0 border-r">
              {{ tab.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--content-->
    <div class="flex-grow">
      <div class="h-full d-theme-dark-bg">
        <TabApList v-show="tabs.active === 'AP_LIST'" :is-active="tabs.active === 'AP_LIST'"/>
        <TabPv v-show="tabs.active === 'PV'" :is-active="tabs.active === 'PV'"/>
        <TabPaymentRequest v-show="tabs.active === 'PAYMENT_REQUEST'" :is-active="tabs.active === 'PAYMENT_REQUEST'"/>
        <TabApPaid v-show="tabs.active === 'AP_PAID'" :is-active="tabs.active === 'AP_PAID'"/>
      </div>
    </div>
  </div>
</template>

<script>
import moduleAp from '@/store/modules/accounting/ap/ap.store'
import TabApList from '@/views/pages/accounting/account-payable/ap/tabs/TabApList'
import TabPv from '@/views/pages/accounting/account-payable/ap/tabs/TabPaymentVoucher'
import TabPaymentRequest from '@/views/pages/accounting/account-payable/ap/tabs/TabPaymentRequest'
import TabApPaid from '@/views/pages/accounting/account-payable/ap/tabs/TabApPaid'

export default {
  name: 'AccountPayable',
  components: {
    TabPaymentRequest,
    TabApList,
    TabPv,
    TabApPaid
  },
  data () {
    return {
      tabs: {
        active: 'AP_LIST',
        items: [
          { code: 'AP_LIST', title: 'A/P List' },
          { code: 'PV', title: 'Payment Voucher' },
          { code: 'PAYMENT_REQUEST', title: 'Payment Request' },
          { code: 'AP_PAID', title: 'A/P Paid' }
        ]
      }
    }
  },
  beforeCreate () {
    this.$store.registerModule(['accounting', 'ap'], moduleAp)
  },
  beforeDestroy () {
    this.$store.commit('accounting/ap/RESET_STATE')
    this.$store.unregisterModule(['accounting', 'ap'])
  }
}
</script>
